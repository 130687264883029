<!--
#***********************************************
#
#      Filename: src/layout/components/Sidebar/Logo.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: LOGO
#        Create: 2021-08-22 16:23:07
# Last Modified: 2021-11-05 17:08:09
#***********************************************
-->
<template>
  <div
    class="sidebar-logo-container"
    :class="{ collapse: collapse }"
    :style="{
      backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg
    }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1
          v-else
          class="sidebar-title"
          :style="{
            color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle
          }">
          {{ title }}
        </h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo" />
        <h1
          class="sidebar-title"
          :style="{
            color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle
          }">
          {{ title }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
  import logoImg from '@/assets/logo/logo.png'
  import variables from '@/assets/styles/variables.scss'
  import { title } from '@/settings'

  export default {
    name: 'SidebarLogo',

    props: {
      collapse: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        title: title,
        logo: logoImg
      }
    },

    computed: {
      variables() {
        return variables
      },
      sideTheme() {
        return this.$store.state.settings.sideTheme
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
  }

  .sidebarLogoFade-enter,
  .sidebarLogoFade-leave-to {
    opacity: 0;
  }

  .sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #2b2f3a;
    text-align: center;
    overflow: hidden;

    & .sidebar-logo-link {
      height: 100%;
      width: 100%;

      & .sidebar-logo {
        width: 50px;
        height: 34px;
        vertical-align: middle;
        margin-right: 12px;
      }

      & .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 14px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle;
      }
    }

    &.collapse {
      .sidebar-logo {
        margin-right: 0px;
      }
    }
  }
</style>
