<!--
#***********************************************
#
#      Filename: src/layout/components/Sidebar/Item.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 侧边栏菜单项
#        Create: 2021-08-19 10:48:51
# Last Modified: 2021-11-05 14:14:52
#***********************************************
-->
<script>
  export default {
    name: 'MenuItem',
    functional: true,
    props: {
      icon: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      }
    },
    render(h, context) {
      const { icon, title } = context.props
      const vnodes = []

      if (icon) {
        vnodes.push(<svg-icon icon-class={icon} />)
      }

      if (title) {
        vnodes.push(<span slot='title'>{title}</span>)
      }
      return vnodes
    }
  }
</script>
