/*
#***********************************************
#
#      Filename: gz-adserver-front/src/utils/request.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: axios请求封装
#        Create: 2021-08-18 09:59:10
# Last Modified: 2021-11-28 10:26:43
#***********************************************
*/
import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
import { Loading } from 'element-ui'
let loading
var timeoutId = null
var timeoutNumber = 0

function startLoading() {
  timeoutId = setInterval(function () {
    timeoutNumber++
    console.log('timeooutNumber', timeoutNumber)
    if (timeoutNumber > 30) {
      Notification.error({ title: '请求超时' })
      endLoading()
    }
  }, 1000)
  loading = Loading.service({
    fullscreen: true,
    background: 'rgba(0, 0, 0, 0.14)'
  })
}

function endLoading() {
  loading.close()
  clearInterval(timeoutId)
  timeoutId = null
  timeoutNumber = 0
}
// 声明一个对象用于存储请求个数
let needLoadingRequestCount = 0
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}
function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

service.interceptors.request.use(
  (config) => {
    showFullScreenLoading()
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
      config.headers['Gz-token'] = getToken()
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        const part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = propName + '[' + key + ']'
              var subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    tryHideFullScreenLoading()
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    tryHideFullScreenLoading()
    const result = res.data.result
    const code = res.data.code
    const headers = res.headers
    if (
      headers['content-type'] === 'application/octet-stream' ||
      headers['content-type'] === 'application/vnd.ms-excel'
    ) {
      console.log('二进制数据')
      return res
    }

    let msg = ''
    if (res.data.msg) {
      msg = res.data.msg
    } else if (res.data.message) {
      msg = res.data.message
    } else {
      msg = '请求错误,请联系后台管理员'
    }

    if (result || code === 200) {
      return res.data
    } else {
      if (res.data?.message === '未登录或登陆失效') {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/FedLogOut').then(() => {
            router.push({ path: '/login' })
          })
        })
      } else {
        Notification.error({ title: msg })
        return Promise.reject(new Error(msg))
      }
    }
  },
  (error) => {
    console.warn('request err', error)
    tryHideFullScreenLoading()
    const message = error.response.data.message || error.response.data.msg
    const status = error.response.status
    if (message === 'Invalid token' || status === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/FedLogOut').then(() => {
          router.push({ path: '/login' })
        })
      })
    } else if (message === 'Network Error') {
      Message({
        message: '后端接口连接异常',
        type: 'error',
        duration: 5 * 1000
      })
    } else if (message && message.includes('timeout')) {
      Message({
        message: '系统接口请求超时',
        type: 'error',
        duration: 5 * 1000
      })
    } else if (status === 400) {
      Notification({
        title: '请求错误',
        message: '系统接口请求异常,请联系后台管理员',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
