/*
#***********************************************
#
#      Filename: src/api/common/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 公共服务
#        Create: 2021-11-05 14:39:19
# Last Modified: 2021-11-29 11:22:30
#***********************************************
*/
import request from '@/utils/request'

// 添加人员
export function parkingList() {
  return request({
    url: '/auth/relationParking/list',
    method: 'get'
  })
}

// 文件上传
export function fileUpload(postData, queryData) {
  return request({
    url: '/common/fileUpload',
    method: 'post',
    data: postData,
    params: queryData
  })
}

// 区域列表
export function regionTree(params) {
  return request({
    url: '/common/region/treeList/cn',
    method: 'get'
  })
}

export function operList() {
  return request({
    url: '/subaccount/list?size=9999&current=1',
    method: 'get'
  })
}
