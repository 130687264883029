<!--
#***********************************************
#
#      Filename: src/App.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: app.vue
#        Create: 2021-10-25 10:49:36
# Last Modified: 2021-10-25 10:49:52
#***********************************************
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App'
  }
</script>
