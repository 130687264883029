/*
#***********************************************
#
#      Filename: src/utils/auth.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 权限token相关
#        Create: 2021-08-18 21:48:09
# Last Modified: 2021-11-27 10:06:27
#***********************************************
*/

const TokenKey = 'GZ-Token'
const UserInfoKey = 'GZ-Info'
const ParkingListKey = 'GZ-ParkingList'
const CurrentParkingKey = 'GZ-CP'
const PermissionKey = 'GZ-PER'
const PlatformKey = 'GZ-PLAT'
const communityListKey = 'GZ-CommunityList'
const OperListKey = 'GZ-OperList'

export function getOperList() {
  return getArrayFromLocalStorage(OperListKey)
}
export function setOperList(operList) {
  return setDataToLocalStorage(OperListKey, operList)
}

export function getToken() {
  return getStringFromLocalStorage(TokenKey)
}
export function setToken(token) {
  return setDataToLocalStorage(TokenKey, token)
}

export function removeToken() {
  return removeDataFromLocalStorage(TokenKey)
}

export function getUserInfo() {
  return getDataFromLocalStorage(UserInfoKey)
}

export function setUserInfo(userinfo) {
  setDataToLocalStorage(UserInfoKey, userinfo)
}

export function removeUserInfo() {
  removeDataFromLocalStorage(UserInfoKey)
}

export function getParkingList() {
  return getArrayFromLocalStorage(ParkingListKey)
}

export function setParkingList(parkingList) {
  setDataToLocalStorage(ParkingListKey, parkingList)
}

export function getCommunityList() {
  return getArrayFromLocalStorage(communityListKey)
}

export function setCommunityList(communityList) {
  return setDataToLocalStorage(communityListKey, communityList)
}

export function getCurrentParking() {
  return getDataFromLocalStorage(CurrentParkingKey)
}

export function setCurrentParking(data) {
  setDataToLocalStorage(CurrentParkingKey, data)
}

export function getPermissions() {
  return getDataFromLocalStorage(PermissionKey)
}

export function setPermissions(data) {
  return setDataToLocalStorage(PermissionKey, data)
}

export function getPlatform() {
  return getDataFromLocalStorage(PlatformKey)
}

export function setPlatform(data) {
  return setDataToLocalStorage(PlatformKey, data)
}
export function clearAll() {
  localStorage.clear()
  console.log('已清除')
}

function getDataFromLocalStorage(key) {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {}
  } catch (err) {
    return false
  }
}
function getArrayFromLocalStorage(key) {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []
  } catch (err) {
    return []
  }
}
function getStringFromLocalStorage(key) {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : ''
  } catch (err) {
    return ''
  }
}
function setDataToLocalStorage(key, data) {
  return localStorage.setItem(key, JSON.stringify(data))
}
function removeDataFromLocalStorage(key, data) {
  try {
    localStorage.removeItem(key)
  } catch (err) {
    console.log(err)
  }
}
