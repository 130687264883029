/*
#***********************************************
#
#      Filename: src/settings.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 配置
#        Create: 2021-08-19 23:10:40
# Last Modified: 2021-11-05 13:51:50
#***********************************************
*/
module.exports = {
  title: '国臻报表管理系统',
  /**
   * 侧边栏主题 深色主题theme-dark，浅色主题theme-light
   */
  sideTheme: 'theme-dark',
  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,
  /**
   * 是否固定头部
   */
  fixedHeader: false,
  /**
   * 是否显示logo
   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
}
