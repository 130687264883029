<!--
#***********************************************
#
#      Filename: src/components/Screenfull/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 全屏按键
#        Create: 2021-11-05 16:47:51
# Last Modified: 2021-11-05 17:00:19
#***********************************************
-->
<template>
  <div>
    <svg-icon
      :icon-class="isFullscreen ? 'exit-fullscreen' : 'fullscreen'"
      :style="{
        fill: sideTheme === 'theme-dark' ? 'white' : 'black'
      }"
      @click="click" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import screenfull from 'screenfull'

  export default {
    name: 'Screenfull',

    data() {
      return {
        isFullscreen: false
      }
    },

    computed: {
      ...mapState({
        theme: (state) => state.settings.theme,
        sideTheme: (state) => state.settings.sideTheme,
        sidebar: (state) => state.app.sidebar,
        device: (state) => state.app.device,
        showSettings: (state) => state.settings.showSettings,
        needTagsView: (state) => state.settings.tagsView,
        fixedHeader: (state) => state.settings.fixedHeader
      })
    },

    mounted() {
      this.init()
    },

    beforeDestroy() {
      this.destroy()
    },

    methods: {
      click() {
        if (!screenfull.isEnabled) {
          this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
          return false
        }
        screenfull.toggle()
      },
      change() {
        this.isFullscreen = screenfull.isFullscreen
      },
      init() {
        if (screenfull.isEnabled) {
          screenfull.on('change', this.change)
        }
      },
      destroy() {
        if (screenfull.isEnabled) {
          screenfull.off('change', this.change)
        }
      }
    }
  }
</script>

<style scoped>
  .screenfull-svg {
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    vertical-align: 10px;
  }
</style>
