/*
#***********************************************
#
#      Filename: src/api/auth/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 账号权限相关接口
#        Create: 2021-10-27 13:02:42
# Last Modified: 2021-11-05 11:38:46
#***********************************************
*/
import request from '@/utils/request'

// 获取验证码图片
export function captchaImg() {
  return request({
    url: '/auth/captcha.jpg',
    method: 'get',
    reponseType: 'blob'
  })
}

// 修改密码
export function changePwd(data) {
  return request({
    url: '/auth/changePwd',
    method: 'post',
    data: data
  })
}

// 获取当前登录用户信息
export function currentUser() {
  return request({
    url: '/auth/current',
    method: 'get'
  })
}

// 用户登录
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    params: data
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'get'
  })
}
