<!--
#***********************************************
#
#      Filename: src/layout/components/Navbar.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: Navbar布局组件
#        Create: 2021-08-19 10:42:07
# Last Modified: 2021-11-27 15:23:46
#***********************************************
-->
<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar" />
    <div v-if="platform === 'platformOne'" class="left-menu">
      <el-dropdown trigger="click" @command="handleCommand">
        <span
          class="el-dropdown-link"
          :style="{
            color: sideTheme === 'theme-dark' ? 'white' : 'black'
          }">
          当前停车场:
          <span class="gz-current-parking">
            {{ currentParking && currentParking.name }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in parkingList" :key="index" :command="item.id">
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div
          class="avatar-wrapper"
          :style="{
            color: sideTheme === 'theme-dark' ? 'white' : 'black'
          }">
          <img :src="userPhoto" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Hamburger from '@/components/Hamburger'
  import Screenfull from '@/components/Screenfull'
  import DefaultUserPhoto from '@/assets/images/default-userphoto.png'

  export default {
    components: {
      Hamburger,
      Screenfull
    },

    computed: {
      ...mapState({
        theme: (state) => state.settings.theme,
        sideTheme: (state) => state.settings.sideTheme,
        sidebar: (state) => state.app.sidebar,
        device: (state) => state.app.device,
        showSettings: (state) => state.settings.showSettings,
        needTagsView: (state) => state.settings.tagsView,
        fixedHeader: (state) => state.settings.fixedHeader,
        parkingList: (state) => state.user.parkingList,
        pid: (state) => state.user.pid,
        currentParking: (state) => state.user.currentParking,
        platform: (state) => state.permission.platform
      }),
      ...mapGetters(['sidebar', 'avatar', 'device']),
      userPhoto() {
        return this.avatar ? this.avatar : DefaultUserPhoto
      },

      setting: {
        get() {
          return this.$store.state.settings.showSettings
        },
        set(val) {
          this.$store.dispatch('settings/changeSetting', {
            key: 'showSettings',
            value: val
          })
        }
      }
    },

    methods: {
      /**
       * 侧边栏展开折叠切换
       */
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },

      /**
       * 登出系统
       */
      async logout() {
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store
            .dispatch('user/LogOut')
            .then(() => {
              this.$store.commit('permission/SET_ROUTES', [])
              this.$store.commit('permission/SET_SIDEBAR_ROUTERS', [])
              this.$store.commit('permission/SET_PLATFORM', null)
              this.$router.push({
                path: '/',
                params: { t: new Date().valueOf() }
              })
            })
            .catch((e) => {
              console.log('登出错误', e)
            })
        })
      },

      /**
       * dropdownItem指令事件
       * @param {string} command 指令(这里的指令内容是停车场id)
       */
      handleCommand(command) {
        this.setCurrentParking(command)
      },

      /**
       * 设置当前停车场和小区
       * @param {int} id 停车场id
       */
      setCurrentParking(id) {
        const parkingItem = this.parkingList.filter((item) => {
          return item.id === id
        })
        this.$store.dispatch('user/changeParking', parkingItem[0])
        location.reload()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }

    .errLog-container {
      display: inline-block;
      vertical-align: top;
    }

    .left-menu {
      float: left;
      height: 100%;
      line-height: 50px;
    }

    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background 0.3s;

          &:hover {
            background: rgba(0, 0, 0, 0.025);
          }
        }
      }

      .avatar-container {
        margin-right: 30px;

        .avatar-wrapper {
          margin-top: 5px;
          position: relative;

          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }
    }
    .gz-current-parking {
      margin-left: 10px;
      cursor: pointer;
      color: #faa13c;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
