/*
#***********************************************
#
#      Filename: src/api/common/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 小区与停车场管理相关api
#        Create: 2021-11-05 14:39:19
# Last Modified: 2021-11-29 11:22:30
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 创建小区
export function comCreate(data) {
  return request({
    url: '/community/create',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 根据id获取小区信息
export function comInfo(id, queryData) {
  return request({
    url: '/community/info/' + id,
    method: 'get',
    params: queryData
  })
}

// 小区列表
export function comList(queryData) {
  return request({
    url: '/community/list',
    method: 'get',
    params: queryData
  })
}

// 创建停车场
export function parkCreate(id, data) {
  return request({
    url: '/parking/create/' + id,
    method: 'post',
    data: data
  })
}

// 根据id获取停车场信息
export function parkInfo(id) {
  return request({
    url: '/parking/info/' + id,
    method: 'get'
  })
}

// 停车场列表
export function parkList(queryData) {
  return request({
    url: '/parking/list',
    method: 'get',
    params: queryData
  })
}

// 更新停车场
export function parkUpdate(id, data) {
  return request({
    url: '/parking/update/' + id,
    method: 'post',
    data: data
  })
}

// 删除停车场
export function parkDel(id) {
  return request({
    url: '/parking/delete/' + id,
    method: 'post'
  })
}

// 停车场云端应用信息
export function parkAppInfo(id) {
  return request({
    url: '/parking/cloudApp/info/' + id,
    method: 'get'
  })
}
