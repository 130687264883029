<!--
#***********************************************
#
#      Filename: src/layout/components/Sidebar/SidebarItem.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 侧边栏菜单项
#        Create: 2021-08-19 10:47:44
# Last Modified: 2021-10-27 22:03:09
#***********************************************
-->
<template>
  <div v-if="!item.hidden">
    <!-- 当前菜单项没有只有一个可显示子项的时候，会将此子项作为菜单显示 -->
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      ">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        v-has-permi="[child.meta.permission]"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu" />
      <div v-if="item.name === 'Manage'" class="add-community" @click="handleAddCommunity">
        <i class="el-icon-circle-plus-outline" />
      </div>
    </el-submenu>

    <!-- 添加修改dialog -->
    <el-dialog title="添加区域" :visible.sync="modalAddComShow" append-to-body width="500px" class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="modalAddComData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="区域名称:" prop="name">
              <el-input v-model="modalAddComData.name" placeholder="请输入区域名称" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import path from 'path'
  import { isExternal } from '@/utils/validate'
  import Item from './Item'
  import AppLink from './Link'
  import FixiOSBug from './FixiOSBug'
  import { comCreate } from '@/api/community'

  export default {
    name: 'SidebarItem',

    components: { Item, AppLink },

    mixins: [FixiOSBug],

    props: {
      // 菜单项
      item: {
        type: Object,
        required: true
      },

      isNest: {
        type: Boolean,
        default: false
      },

      // 路由基础路径
      basePath: {
        type: String,
        default: ''
      }
    },

    data() {
      this.onlyOneChild = null
      return {
        modalAddComShow: false,
        modalAddComData: {
          name: ''
        },
        rules: {
          name: [
            {
              required: true,
              message: '小区名称不能为空',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 30,
              message: '小区名称长度在2-30个字符之间'
            }
          ]
        }
      }
    },

    methods: {
      /**
       * 判断菜单项是否只有一个展示子项
       * @param {array} children 菜单项子项
       * @param {object} parent 菜单项
       * @return {bool}
       */
      hasOneShowingChild(children = [], parent) {
        if (!children) {
          children = []
        }

        // 筛选菜单可显示子项
        const showingChildren = children.filter((item) => {
          if (item.hidden) {
            return false
          } else {
            // 暂存子项（会在只有一个可显示子项是用到）
            this.onlyOneChild = item
            return true
          }
        })

        // When there is only one child router, the child router is displayed by default
        // 当只有一个可显示子项时，会默认显示子项
        if (showingChildren.length === 1) {
          return true
        }

        // Show parent if there are no child router to display
        // 当没有子项可显示时，会将父菜单作为子项
        if (showingChildren.length === 0) {
          this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
          return true
        }

        return false
      },

      /**
       * 构造路由路径
       * @param {string} routePath 路由路径
       * @return {string}
       */
      resolvePath(routePath) {
        if (isExternal(routePath)) {
          return routePath
        }
        if (isExternal(this.basePath)) {
          return this.basePath
        }
        return path.resolve(this.basePath, routePath)
      },

      /**
       * 添加区域按键点击触发事件
       */
      handleAddCommunity() {
        this.modalAddComShow = true
      },

      /**
       * 添加区域弹框取消点击事件
       */
      cancel() {
        this.modalAddComShow = false
      },

      /**
       * 添加区域弹框确认点击事件
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            comCreate(this.modalAddComData).then((res) => {
              if (res.result) {
                _this.noticeSuccess('创建成功,请退出系统重新登录')
                _this.modalAddComShow = false
              } else {
                _this.noticeError(res.message)
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .add-community {
    height: 50px;
    width: 100%;
    background: rgba(250, 161, 60, 0.8);
    display: grid;
    align-items: center;
    justify-content: center;
    color: #cccccc;
    &:hover {
      background: #faa13c;
      color: white;
    }
  }
</style>
