/*
#***********************************************
#
#      Filename: src/store/modules/app.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: app 变量
#        Create: 2021-08-18 23:01:22
# Last Modified: 2021-11-10 15:53:00
#***********************************************
*/

const state = {
  operList: []
}

const mutations = {
  INIT: (state, data) => {
    state.operList = data
  },
  CLEAN: (state) => {
    state.operList = []
  },
  ID: (state, id) => {
    const oper = state.operList.filter((item) => (item.id = id))
    if (oper && oper.length > 1) {
      return oper[0]
    }
    return null
  }
}

const actions = {
  init(commit, data) {
    commit('INIT', data)
  },
  clean(commit) {
    commit('CLEAN')
  },
  id(commit, data) {
    return commit('ID', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
