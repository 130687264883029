<!--
#***********************************************
#
#      Filename: src/layout/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 整体布局
#        Create: 2021-08-18 22:08:51
# Last Modified: 2021-11-05 13:45:49
#***********************************************
-->
<template>
  <div :class="classObj" class="app-wrapper" :style="{ '--current-color': theme }">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar
      class="sidebar-container"
      :style="{
        backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg
      }" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar
          :style="{
            backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg
          }" />
        <div class="breadcrumb-wrapper">
          <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
        </div>
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from '@/components/Breadcrumb'
  import RightPanel from '@/components/RightPanel'
  import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
  import ResizeMixin from './mixin/ResizeHandler'
  import { mapState } from 'vuex'
  import variables from '@/assets/styles/variables.scss'

  export default {
    name: 'Layout',
    components: {
      Breadcrumb,
      AppMain,
      Navbar,
      RightPanel,
      Settings,
      Sidebar,
      TagsView
    },

    mixins: [ResizeMixin],

    computed: {
      ...mapState({
        theme: (state) => state.settings.theme,
        sideTheme: (state) => state.settings.sideTheme,
        sidebar: (state) => state.app.sidebar,
        device: (state) => state.app.device,
        showSettings: (state) => state.settings.showSettings,
        needTagsView: (state) => state.settings.tagsView,
        fixedHeader: (state) => state.settings.fixedHeader
      }),

      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
          mobile: this.device === 'mobile'
        }
      },

      variables() {
        return variables
      }
    },

    methods: {
      handleClickOutside() {
        this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/mixin.scss';
  @import '~@/assets/styles/variables.scss';
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
