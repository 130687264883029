/*
#***********************************************
#
#      Filename: src/store/modules/permission.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 菜单权限
#        Create: 2021-08-18 22:50:55
# Last Modified: 2021-11-27 15:26:58
#***********************************************
*/
import { constantRoutes } from '@/router'
import { getPlatform, setPlatform } from '@/utils/auth'

const permission = {
  namespaced: true,
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [],
    platform: getPlatform()
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.sidebarRouters = constantRoutes.concat(routers)
    },
    SET_PLATFORM: (state, payload) => {
      setPlatform(payload)
      state.platform = payload
    }
  }
}

/**
 * 加载组件
 * @param {string} string
 * @retrun {object}
 */
export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
