/*
#***********************************************
#
#      Filename: gz-adserver-front/src/router/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 路由配置
#        Create: 2021-08-18 11:22:33
# Last Modified: 2021-11-27 15:41:06
#***********************************************
*/
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  {
    name: 'Main',
    path: '/',
    component: (resolve) => require(['@/views/Main/Index'], resolve),
    meta: {},
    hidden: true
  },
  {
    path: '/platformOneLogin',
    component: (resolve) => require(['@/views/PlatformOne/Account/Login'], resolve),
    meta: {},
    hidden: true
  },
  {
    path: '/platformTwoLogin',
    component: (resolve) => require(['@/views/PlatformTwo/Account/Login'], resolve),
    meta: {},
    hidden: true
  },
  {
    path: '/register',
    component: (resolve) => require(['@/views/PlatformTwo/Account/Register'], resolve),
    meta: {},
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    meta: {},
    children: [
      {
        path: '/redirect/:path(.*)',
        meta: {},
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    meta: {},
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    meta: {},
    hidden: true
  }
]

// 驾到智云车管平台路由
export const platformOneRoutes = [
  {
    path: '/workplate',
    component: Layout,
    // redirect: 'noRedirect',
    meta: { permission: true },
    children: [
      {
        path: 'plate',
        component: (resolve) => require(['@/views/PlatformOne/index'], resolve),
        name: '工作台',
        meta: {
          title: '工作台',
          icon: 'work-plate',
          noCache: true,
          affix: true,
          meta: { permission: 'workplate' }
        }
      }
    ]
  },
  {
    path: '/vacancy',
    component: Layout,
    meta: { permission: true },
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/PlatformOne/Vacancy'], resolve),
        name: '余位管理',
        meta: {
          title: '余位管理',
          icon: 'work-plate',
          noCache: false,
          affix: false,
          meta: { permission: true }
        }
      }
    ]
  },
  {
    path: '/deviceLogs',
    component: Layout,
    meta: { permission: true },
    children: [
      {
        path: 'list',
        component: (resolve) => require(['@/views/PlatformOne/DeviceLogs'], resolve),
        name: '设备日志',
        meta: {
          title: '设备日志',
          icon: 'work-plate',
          noCache: false,
          affix: false,
          meta: { permission: true }
        }
      }
    ]
  },

  // 名单信息
  {
    path: '/list',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: '名单管理',
    meta: { title: '名单信息', icon: 'list', permission: 'list' },
    children: [
      {
        path: 'Room',
        component: (reslove) => require(['@/views/PlatformOne/List/Room'], reslove),
        name: '房间管理',
        meta: {
          title: '房间管理',
          icon: 'room',
          noCache: false,
          permission: 'house:info'
        }
      },
      {
        path: 'OwnerList',
        component: (reslove) => require(['@/views/PlatformOne/List/OwnerList'], reslove),
        name: '业主管理',
        meta: {
          title: '业主管理',
          icon: 'owner',
          noCache: false,
          permission: 'housing:owner:info'
        }
      },
      {
        path: 'Shop',
        component: (reslove) => require(['@/views/PlatformOne/List/shopManager'], reslove),
        name: '商铺管理',
        meta: {
          title: '商铺管理',
          icon: 'build',
          noCache: false,
          permission: 'shop:info'
        }
      },
      {
        path: 'MerchantList',
        component: (reslove) => require(['@/views/PlatformOne/List/MerchantList'], reslove),
        name: 'MerchantList',
        meta: {
          title: '商户管理',
          icon: 'merchant',
          noCache: false,
          permission: 'consume-limit'
        }
      },
      {
        path: 'Parking',
        component: (reslove) => require(['@/views/PlatformOne/List/Parking'], reslove),
        name: '车位管理',
        meta: {
          title: '车位管理',
          icon: 'plate',
          noCache: false,
          permission: 'parking:place'
        }
      },
      {
        path: 'ParkingSpaceSales',
        component: (reslove) => require(['@/views/PlatformOne/List/ParkingSpaceSales'], reslove),
        name: '车位销售',
        meta: {
          title: '车位销售',
          icon: 'plate',
          noCache: false,
          permission: 'sellinfo'
        }
      },
      {
        path: 'FixedParkingSpace',
        component: (reslove) => require(['@/views/PlatformOne/List/FixedParkingSpace'], reslove),
        name: '固定车位',
        meta: {
          title: '固定车位',
          icon: 'work-plate',
          noCache: false,
          permission: 'fixed-parking'
        }
      },
      {
        path: 'UnFixedParkingSpace',
        component: (reslove) => require(['@/views/PlatformOne/List/UnFixedParkingSpace'], reslove),
        name: 'UnFixedParkingSpace',
        meta: {
          title: '非固定车位',
          icon: 'work-plate',
          noCache: false,
          permission: 'unfixed-parking'
        }
      },
      {
        path: 'MonthList',
        component: (reslove) => require(['@/views/PlatformOne/List/MonthList'], reslove),
        name: 'MonthList',
        meta: {
          title: '月租名单',
          icon: 'work-plate',
          noCache: false,
          permission: 'parking:vip'
        }
      },
      {
        path: 'WhiteList',
        component: (reslove) => require(['@/views/PlatformOne/List/WhiteList'], reslove),
        name: '白名单',
        meta: {
          title: '白名单',
          icon: 'white-list',
          noCache: false,
          permission: 'whitelist'
        }
      },

      {
        path: 'GrayList',
        component: (reslove) => require(['@/views/PlatformOne/List/GrayList'], reslove),
        name: '灰名单',
        meta: {
          title: '灰名单',
          icon: 'gray-list',
          noCache: false,
          permission: 'graylist'
        }
      },
      {
        path: 'BlackList',
        component: (reslove) => require(['@/views/PlatformOne/List/BlackList'], reslove),
        name: 'BlackList',
        meta: {
          title: '黑名单',
          icon: 'black-list',
          noCache: false,
          permission: 'blacklist'
        }
      },
      {
        path: 'FreeOrderList',
        component: (reslove) => require(['@/views/PlatformOne/List/FreeOrderList'], reslove),
        name: '限时免费名单',
        meta: {
          title: '限时免费名单',
          icon: 'white-list',
          noCache: false,
          permission: 'manager:pmTimeLimit:index'
        }
      }
      // {
      //   path: 'RentManagement',
      //   component: (reslove) => require(['@/views/PlatformOne/List/RentManagement'], reslove),
      //   name: 'RentManagement',
      //   meta: {
      //     title: '租金管理名单',
      //     icon: 'peoples',
      //     noCache: false,
      //     permission: 'list:owner:peoples'
      //   }
      // },
      // {
      //   path: 'PropertyFeeManagement',
      //   component: (reslove) => require(['@/views/PlatformOne/List/PropertyFeeManagement'], reslove),
      //   name: 'PropertyFeeManagement',
      //   meta: {
      //     title: '物业费管理名单',
      //     icon: 'money',
      //     noCache: false,
      //     permission: 'house:owner:money'
      //   }
      // }
    ]
  },

  // 车辆进出记录
  {
    path: '/record',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Record',
    meta: { title: '车辆进出记录', icon: 'user', permission: 'parking-record' },
    children: [
      {
        path: 'AccessRecord',
        component: (reslove) => require(['@/views/PlatformOne/Record/AccessRecord'], reslove),
        name: 'AccessRecord',
        meta: {
          title: '车辆进出记录',
          icon: 'work-plate',
          noCache: false,
          permission: 'parking:order:info'
        }
      },
      {
        path: 'PresentVehicles',
        component: (reslove) => require(['@/views/PlatformOne/Record/PresentVehicles'], reslove),
        name: 'PresentVehicles',
        meta: {
          title: '当前在场车辆',
          icon: 'work-plate',
          noCache: false,
          permission: 'present-vehicles'
        }
      },
      {
        path: 'AbnormalRecord',
        component: (reslove) => require(['@/views/PlatformOne/Record/AbnormalRecord'], reslove),
        name: 'AbnormalRecord',
        meta: {
          title: '异常出场订单',
          icon: 'work-plate',
          noCache: false,
          permission: 'abnormal-record'
        }
      }
    ]
  },

  // 缴费明细
  {
    path: '/detail',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Detail',
    meta: { title: '缴费明细', icon: 'user', permission: 'fee-record' },
    children: [
      {
        path: 'MonthDetail',
        component: (reslove) => require(['@/views/PlatformOne/Detail/MonthDetail'], reslove),
        name: 'MonthDetial',
        meta: {
          title: '月租缴费明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'fee-record:parking-vip:info'
        }
      },
      {
        path: 'GrayDetail',
        component: (reslove) => require(['@/views/PlatformOne/Detail/GrayDetail'], reslove),
        name: 'GrayDetial',
        meta: {
          title: '灰名单明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'graylist:payrecord:info'
        }
      },
      {
        path: 'TmpVehicle',
        component: (reslove) => require(['@/views/PlatformOne/Detail/TmpVehicle'], reslove),
        name: 'TmpVehicle',
        meta: {
          title: '临停车明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'parking:order:tmp:info'
        }
      },
      {
        path: 'FixedVehicles',
        component: (reslove) => require(['@/views/PlatformOne/Detail/FixedVehicles'], reslove),
        name: 'FixedVehicles',
        meta: {
          title: '固定车位明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'fee-record:parking-fixed:info'
        }
      },
      {
        path: 'UnFixedVehicles',
        component: (reslove) => require(['@/views/PlatformOne/Detail/UnFixedVehicles'], reslove),
        name: 'UnFixedVehicles',
        meta: {
          title: '非固定车位明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'fee-record:parking-unfixed:info'
        }
      },
      {
        path: 'ParkingSpaceSalesDetail',
        component: (reslove) => require(['@/views/PlatformOne/Detail/ParkingSpaceSalesDetail'], reslove),
        name: 'ParkingSpaceSalesDetail',
        meta: {
          title: '车位销售明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'detail:seinfo:info'
        }
      },
      // {
      //   path: 'RentPayment',
      //   component: (reslove) => require(['@/views/PlatformOne/Detail/RentPayment'], reslove),
      //   name: 'RentPayment',
      //   meta: {
      //     title: '租金缴纳明细',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'fee-record:parking-unfixed:info'
      //   }
      // },
      // {
      //   path: 'PropertyPayment',
      //   component: (reslove) => require(['@/views/PlatformOne/Detail/PropertyPayment'], reslove),
      //   name: 'PropertyPayment',
      //   meta: {
      //     title: '物业缴纳明细',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'fee-record:parking-unfixed:info'
      //   }
      // },
      {
        path: 'Discount',
        component: (reslove) => require(['@/views/PlatformOne/Detail/Discount'], reslove),
        name: 'Discount',
        meta: {
          title: '优免明细',
          icon: 'work-plate',
          noCache: false,
          permission: 'fee-record:merchant-detail:info'
        }
      }
    ]
  },

  // 统计报表
  {
    path: '/report',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Report',
    meta: { title: '统计报表', icon: 'user', permission: 'statistic' },
    children: [
      // {
      //   path: 'StaReport',
      //   component: (reslove) => require(['@/views/PlatformOne/Report/StaReport'], reslove),
      //   name: 'StaReport',
      //   meta: {
      //     title: '统计报表',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'report:finance'
      //   }
      // },
      {
        path: 'ParkingSalesStatistics',
        component: (reslove) => require(['@/views/PlatformOne/Report/ParkingSalesStatistics'], reslove),
        name: 'ParkingSalesStatistics',
        meta: {
          title: '车位销售统计',
          icon: 'work-plate',
          noCache: false,
          permission: 'report:sellinfo:statistics'
        }
      },
      {
        path: 'TmpVehicleReport',
        component: (reslove) => require(['@/views/PlatformOne/Report/TmpVehicleReport'], reslove),
        name: 'TmpVehicleReport',
        meta: {
          title: '统计报表',
          icon: 'work-plate',
          noCache: false,
          permission: 'report:finance:info'
        }
      },
      {
        path: 'dutyReport',
        component: (reslove) => require(['@/views/PlatformOne/Report/DutyReport'], reslove),
        name: 'dutyReport',
        meta: {
          title: '班次报表',
          icon: 'work-plate',
          noCache: false,
          permission: 'report:duty:info'
        }
      },
      {
        path: 'FixedVehiclesReport',
        component: (reslove) => require(['@/views/PlatformOne/Report/FixedVehiclesReport'], reslove),
        name: 'FixedVehiclesReport',
        meta: {
          title: '固定车位报表',
          icon: 'work-plate',
          noCache: false,
          permission: 'report:fixed:info'
        }
      },
      {
        path: 'UnFixedVehiclesReport',
        component: (reslove) => require(['@/views/PlatformOne/Report/UnFixedVehiclesReport'], reslove),
        name: 'AbnormalRecordReport',
        meta: {
          title: '非固定车位报表',
          icon: 'work-plate',
          noCache: false,
          permission: 'report:unfixed:info'
        }
      },
      // {
      //   path: 'RentReportForm',
      //   component: (reslove) => require(['@/views/PlatformOne/Report/RentReportForm'], reslove),
      //   name: 'RentReportForm',
      //   meta: {
      //     title: '租金缴纳报表',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'report:rentForm:info'
      //   }
      // },
      // {
      //   path: 'PropertyReportForm',
      //   component: (reslove) => require(['@/views/PlatformOne/Report/PropertyReportForm'], reslove),
      //   name: 'PropertyReportForm',
      //   meta: {
      //     title: '物业费缴纳报表',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'report:PropertyForm:info'
      //   }
      // },
      {
        path: 'DiscountReport',
        component: (reslove) => require(['@/views/PlatformOne/Report/DiscountReport'], reslove),
        name: 'DiscountReport',
        meta: {
          title: '优免报表',
          icon: 'work-plate',
          noCache: false,
          permission: 'consume-limit:record:report'
        }
      }
    ]
  },

  // 电子合同
  {
    path: '/contract',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Contract',
    meta: { title: '电子合同', icon: 'user', permission: 'agreement' },
    children: [
      {
        path: 'ContractTemplate',
        component: (reslove) => require(['@/views/PlatformOne/Contract/ContractTemplate'], reslove),
        name: 'ContractTemplate',
        meta: {
          title: '合同模板',
          icon: 'work-plate',
          noCache: false,
          permission: 'agreement:tpl:info'
        }
      },
      {
        path: 'ContractItem',
        component: (reslove) => require(['@/views/PlatformOne/Contract/ContractItem'], reslove),
        name: 'ContractItem',
        meta: {
          title: '合同',
          icon: 'work-plate',
          noCache: false,
          permission: 'agreement:info'
        }
      }
    ]
  },

  // 人员管理
  {
    path: '/person',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Person',
    meta: { title: '人员管理', icon: 'user', permission: 'employee' },
    children: [
      {
        path: 'Account',
        component: (reslove) => require(['@/views/PlatformOne/Person/Account'], reslove),
        name: 'Account',
        meta: {
          title: '账号管理',
          icon: 'work-plate',
          noCache: false,
          permission: 'employee:info'
        }
      },
      {
        path: 'Role',
        component: (reslove) => require(['@/views/PlatformOne/Person/Role'], reslove),
        name: 'Role',
        meta: {
          title: '角色管理',
          icon: 'work-plate',
          noCache: false,
          permission: 'role:info'
        }
      },
      {
        path: 'Attendance',
        component: (reslove) => require(['@/views/PlatformOne/Person/Attendance'], reslove),
        name: 'Attendance',
        meta: {
          title: '考勤管理',
          icon: 'work-plate',
          noCache: false,
          permission: 'attendance:info'
        }
      }
    ]
  },

  // 基础设置
  {
    path: '/setting',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Setting',
    meta: { title: '基础设置', icon: 'user', permission: 'settings' },
    children: [
      {
        path: 'ShiftChange',
        component: (reslove) => require(['@/views/PlatformOne/Setting/ShiftChange'], reslove),
        name: 'ShiftChange',
        meta: {
          title: '自动换班管理',
          icon: 'work-plate',
          noCache: false,
          permission: 'autoshift:info'
        }
      },
      {
        path: 'EntranceAndExit',
        component: (reslove) => require(['@/views/PlatformOne/Setting/EntranceAndExit'], reslove),
        name: 'EntranceAndExit',
        meta: {
          title: '进出口设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'passages:info'
        }
      },
      // 2022-04-07暂时屏蔽，原接口有问题
      // {
      //   path: 'OnlinePay',
      //   component: (reslove) => require(
      //     ['@/views/Setting/OnlinePay'],
      //     reslove
      //   ),
      //   name: 'OnlinePay',
      //   meta: {
      //     title: '线上支付设置',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'settings:payment'
      //   }
      // },
      // {
      //   path: 'OnlineInvoice',
      //   component: (reslove) => require(['@/views/Setting/OnlineInvoice'], reslove),
      //   name: 'OnlineInvoice',
      //   meta: {
      //     title: '线上开票设置',
      //     icon: 'work-plate',
      //     noCache: false,
      //     permission: 'settings:invoice'
      //   }
      // },
      {
        path: 'QrCode',
        component: (reslove) => require(['@/views/PlatformOne/Setting/QrCode'], reslove),
        name: 'QrCode',
        meta: {
          title: '二维码设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'settings:qrcode:info'
        }
      },
      {
        path: 'Equipment',
        component: (reslove) => require(['@/views/PlatformOne/Setting/Equipment'], reslove),
        name: 'Equipment',
        meta: {
          title: '操作设备管理',
          icon: 'work-plate',
          noCache: false,
          permission: 'settings:client-device:info'
        }
      },
      {
        path: 'Monthly',
        component: (reslove) => require(['@/views/PlatformOne/Setting/Monthly'], reslove),
        name: 'Monthly',
        meta: {
          title: '车位月租设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'settings:parking-vip:info'
        }
      },
      {
        path: 'SentryBox',
        component: (reslove) => require(['@/views/PlatformOne/Setting/SentryBox'], reslove),
        name: 'SentryBox',
        meta: {
          title: '岗亭设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'sentrybox:info'
        }
      },
      {
        path: 'fee-rule',
        component: (reslove) => require(['@/views/PlatformOne/Setting/fee-rule'], reslove),
        name: 'FeeRule',
        meta: {
          title: '收费规则设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'settings:parking-fee-rule:update'
        }
      },
      {
        path: 'PropertySetUp',
        component: (reslove) => require(['@/views/PlatformOne/Setting/PropertySetUp'], reslove),
        name: 'PropertySetUp',
        meta: {
          title: '物业费设置',
          icon: 'work-plate',
          noCache: false,
          permission: 'settings:property-set:update'
        }
      }
      // {
      //   path: 'Contract',
      //   component: (reslove) => require(['@/views/PlatformOne/Setting/Contract'], reslove),
      //   name: 'ContractSetting',
      //   meta: { title: '合同功能', icon: 'work-plate', noCache: false }
      // },
      // {
      //   path: 'Merchant',
      //   component: (reslove) => require(['@/views/PlatformOne/Setting/Merchant'], reslove),
      //   name: 'Merchant',
      //   meta: { title: '商户设置', icon: 'work-plate', noCache: false }
      // }
    ]
  }
]

// 驾到智云车管平台总账号管理平台路由
export const platformTwoRoutes = [
  {
    path: '/workplate',
    component: Layout,
    meta: { permission: true },
    // redirect: 'noRedirect',
    children: [
      {
        path: 'plate',
        component: (resolve) => require(['@/views/PlatformTwo/index'], resolve),
        name: '工作台',
        meta: {
          title: '工作台',
          icon: 'work-plate',
          noCache: true,
          affix: true,
          permission: true
        }
      }
    ]
  },

  // 名单信息
  {
    path: '/user',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: '企业个人信息',
    meta: { title: '企业个人信息', icon: 'user', permission: true },
    children: [
      {
        path: '/Info',
        component: (reslove) => require(['@/views/PlatformTwo/Account/Info'], reslove),
        name: '资料信息',
        meta: {
          title: '资料信息',
          icon: 'data',
          noCache: false,
          permission: true
        }
      }
    ]
  },

  // 统计报表
  {
    path: '/report',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Report',
    meta: { title: '统计报表', icon: 'report-management', permission: true },
    children: [
      {
        path: 'TmpVehicleReport',
        component: (reslove) => require(['@/views/PlatformTwo/Report/AdminTmpVehicleReport'], reslove),
        name: 'TmpVehicleReport',
        meta: {
          title: '临停车报表',
          icon: 'tmp-record',
          noCache: false,
          permission: true
        }
      },
      {
        path: 'FixedVehiclesReport',
        component: (reslove) => require(['@/views/PlatformTwo/Report/FixedVehiclesReport'], reslove),
        name: 'FixedVehiclesReport',
        meta: {
          title: '固定车位报表',
          icon: 'fixed-record',
          noCache: false,
          permission: true
        }
      },
      {
        path: 'UnFixedVehiclesReport',
        component: (reslove) => require(['@/views/PlatformTwo/Report/UnFixedVehiclesReport'], reslove),
        name: 'AbnormalRecordReport',
        meta: {
          title: '非固定车位报表',
          icon: 'non-fixed-record',
          noCache: false,
          permission: true
        }
      },
      {
        path: 'DiscountReport',
        component: (reslove) => require(['@/views/PlatformTwo/Report/DiscountReport'], reslove),
        name: 'DiscountReport',
        meta: {
          title: '优免报表',
          icon: 'mch-report',
          noCache: false,
          permission: true
        }
      }
    ]
  },

  // 停车场管理
  {
    path: '/manage',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Manage',
    alwaysShow: true,
    meta: { title: '停车场管理', icon: 'parking', permission: true },
    children: []
  },

  // 人员管理
  {
    path: '/role',
    component: Layout,
    hidden: false,
    redirect: 'noredirect',
    name: 'Role',
    meta: { title: '人员管理', icon: 'user', permission: true },
    children: [
      {
        path: 'Account',
        component: (reslove) => require(['@/views/PlatformTwo/Person/Account'], reslove),
        name: 'Account',
        meta: {
          title: '账号管理',
          icon: 'account',
          noCache: false,
          permission: 'employee:info'
        }
      },
      {
        path: 'Role',
        component: (reslove) => require(['@/views/PlatformTwo/Person/Role'], reslove),
        name: 'Role',
        meta: {
          title: '角色管理',
          icon: 'role',
          noCache: false,
          permission: 'role:info'
        }
      }
    ]
  }
]

const router = new Router({
  // 去掉url中的#
  // mode: 'history', history模式由于后端nginx没有配置rewrite,会报404
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

// 免登录白名单
const whiteList = ['/', '/platformOneLogin', '/platformTwoLogin', '/register', '/401', '/404']

// 路由前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start()
  // 用户已登录
  if (getToken()) {
    // 已登录用户跳转登陆页面重定向到相应平台的工作台页面
    if (to.path === '/platformOneLogin') {
      console.log('已登录,跳转到报表平台工作台')
      next({ path: '/workplate/plate' })
    } else if (to.path === '/platformTwoLogin') {
      console.log('已登录,跳转到报表账号管理总平台工作台')
      next({ path: '/workplate/plate' })
    }
    if (store.getters.sidebarRouters.length || to.name !== null) {
      next()
    } else {
      // 获取当前登录平台
      const platform = store.getters.platform
      // 用户平台
      if (platform === 'platformOne') {
        window.localStorage.setItem('storageAside', '1')
        store.commit('permission/SET_ROUTES', platformOneRoutes)
        store.commit('permission/SET_SIDEBAR_ROUTERS', platformOneRoutes)
        // router.matcher = new Router().matcher
        router.addRoutes(platformOneRoutes)
      } else {
        const manageIndex = platformTwoRoutes.findIndex((item) => item.name === 'Manage')
        const manageRouteChildren = []
        const communityList = store.state.user.communityList
        communityList.forEach((item) => {
          const childRoute = {
            path: '/manage/' + item.id,
            component: (resolve) => import('@/views/PlatformTwo/Manage/Manage'),
            name: 'Manage',
            params: {
              id: item.id
            },
            meta: { title: item.name, icon: 'area', permission: true }
          }
          manageRouteChildren.push(childRoute)
        })
        platformTwoRoutes[manageIndex].children = manageRouteChildren
        store.commit('permission/SET_ROUTES', platformTwoRoutes)
        store.commit('permission/SET_SIDEBAR_ROUTERS', platformTwoRoutes)
        router.addRoutes(platformTwoRoutes)
      }
      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
    }
  } else {
    console.log('next', next)
    console.log('to.path', to)
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      console.log('免登陆路由跳转', to.path)
      next()
    } else {
      // 否则全部重定向到首页
      console.log('未登录跳转到主页')
      next({ path: '/' })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
