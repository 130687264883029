/**
 * #***********************************************
 * #
 * #      Filename: src/store/modules/user.js
 * #
 * #        Author: wwj - 318348750@qq.com
 * #       Company: 甘肃国臻物联网科技有限公司
 * #   Description: vuex user module
 * #        Create: 2021-08-18 11:41:04
 * # Last Modified: 2021-11-27 10:11:18
 * #***********************************************
 *
 * @format
 */

import { login, logout } from '@/api/auth'
import { parkingList, operList } from '@/api/common'

import DefaultUserPhoto from '@/assets/images/default-userphoto.png'
import {
  getToken,
  setToken,
  removeToken,
  setUserInfo,
  getUserInfo,
  getParkingList,
  setParkingList,
  getOperList,
  setOperList,
  setCurrentParking,
  getCurrentParking,
  setPermissions,
  getPermissions,
  getCommunityList,
  clearAll
} from '@/utils/auth'

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    roles: [],
    userInfo: getUserInfo(),
    parkingList: getParkingList(),
    operList: getOperList(),
    currentParking: getCurrentParking(),
    permissions: getPermissions(),
    pid: null,
    communityId: null,
    communityList: getCommunityList()
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PID: (state, parking) => {
      state.pid = parking.pid
    },
    SET_COMMUNITY: (state, communityId) => {
      state.communityId = communityId
    },
    SET_PARKING_LIST: (state, data) => {
      state.parkingList = data
    },
    SET_OPER_LIST: (state, data) => {
      state.operList = data
    },
    SET_CURRENTPARKING: (state, data) => {
      state.currentParking = data
    },
    SET_COMMUNITY_LIST: (state, data) => {
      state.communityList = data
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const data = {}
      data.username = userInfo.account.trim()
      data.password = userInfo.password
      data.captchaCode = userInfo.captcha.trim()
      data.applyId = userInfo.applyId
      data.rememberMe = userInfo.rememberMe
      data.applyId = userInfo.applyId
      return new Promise((resolve, reject) => {
        login(data)
          .then((res) => {
            // 设置token
            setToken(res.data.token)
            commit('SET_TOKEN', res.data.token)
            // 设置用户信息
            const data = {
              userId: res.data.userId,
              username: res.data.username,
              name: res.data.name,
              roleId: res.data.roleId,
              userType: res.data.userType,
              avatar: res.data.avatar || DefaultUserPhoto
            }
            setUserInfo(data)
            commit('SET_USER', data)

            // 设置权限
            console.log('res.data.permissions', res.data.permissions)
            setPermissions(res.data.permissions)
            commit('SET_PERMISSIONS', res.data.permissions)

            // 获取用户停车场列表
            parkingList()
              .then((res) => {
                setParkingList(res.data)
                commit('SET_PARKING_LIST', res.data)
                // 默认将第一个设为当前停车场
                setCurrentParking(res.data[0])
                commit('SET_CURRENTPARKING', res.data[0])
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
            operList()
              .then((res) => {
                console.log('res', res)
                if (res.result && res.data.records) {
                  const data = res.data.records
                  setOperList(data)
                  commit('SET_OPER_LIST', data)
                  resolve()
                } else {
                  reject(res.data.message)
                }
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        // 获取角色跟权限信息
        const user = state.userInfo

        if (user) {
          resolve(user)
        } else {
          reject(new Error('用户未登录'))
        }
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_USER', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_PID', { pid: null })
            commit('SET_PARKING_LIST', [])
            commit('SET_CURRENTPARKING', {})
            commit('SET_COMMUNITY', null)
            commit('SET_COMMUNITY_LIST', [])
            commit('SET_OPER_LIST', [])
            clearAll()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    // 切换当前停车场
    changeParking({ commit }, data) {
      setCurrentParking(data)
      commit('SET_CURRENTPARKING', data)
    },

    // 设置停车场列表
    setParkingList({ commit }, data) {
      setParkingList(data)
      commit('SET_PARKING_LIST', data)
    },
    // 设置当前单位所有人员信息
    setOperList({ commit }, data) {
      console.log(data)
      setOperList(data)
      commit('SET_OPER_LIST', data)
    }
  }
}

export default user
