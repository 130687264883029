/*
#***********************************************
#
#      Filename: src/store/modules/user.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: vuex getters
#        Create: 2021-08-18 11:41:04
# Last Modified: 2021-11-11 22:24:30
#***********************************************
*/
const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  uid: (state) => state.user.userInfo.id,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  permission_routes: (state) => state.permission.routes,
  sidebarRouters: (state) => state.permission.sidebarRouters,
  userType: (state) => state.user.userInfo.userType,
  platform: (state) => state.permission.platform,
  operList: (state) => state.oper.operList
}
export default getters
