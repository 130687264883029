/*
#***********************************************
#
#      Filename: src/store/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: vuex
#        Create: 2021-11-11 20:46:01
# Last Modified: 2021-11-11 20:47:25
#***********************************************
*/
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import oper from './modules/oper'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    oper
  },
  getters
})

export default store
