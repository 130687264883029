/*
#***********************************************
#
#      Filename: src/main.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 入口文件
#        Create: 2021-08-21 16:29:53
# Last Modified: 2021-11-18 14:31:09
#***********************************************
*/
import Vue from 'vue'
import Cookies from 'js-cookie'
import Element from 'element-ui'
import '@/assets/styles/element-variables.scss'
import '@/assets/styles/index.scss'
import '@/assets/styles/ruoyi.scss'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
import './assets/icons'
import { parseTime, resetForm, addDateRange, download, handleTree } from '@/utils/gz-base'
import Pagination from '@/components/Pagination'
import DetailPagination from '@/components/detailPagination'
import 'default-passive-events'
import * as echarts from 'echarts'

import exportPdf from './utils/exportPdf'

// 自定义表格工具扩展
import RightToolbar from '@/components/RightToolbar'

// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.$echarts = echarts
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  })
}

Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  })
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg)
}

Vue.prototype.noticeSuccess = function (msg) {
  this.$notify({
    title: '操作成功',
    message: msg,
    type: 'success'
  })
}

Vue.prototype.noticeError = function (msg) {
  this.$notify({
    title: '操作失败',
    message: msg,
    type: 'error'
  })
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('DetailPagination', DetailPagination)
Vue.component('RightToolbar', RightToolbar)

Vue.use(permission)
Vue.use(exportPdf)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = true
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})

Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', (e) => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 3000)
    })
  }
})
